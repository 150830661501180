/*
$ka-border-color: #F9FBFC !default;
$ka-color-base: #353C44 !default;
$ka-thead-color: #747D86 !default;

$ka-background-color: white !default;
$ka-input-background-color: null !default;
$ka-input-border-color: null !default;
$ka-input-color: null !default;
$ka-cell-color: $ka-color-base !default;
$ka-cell-hover-background-color: null !default;
$ka-cell-line-height: 29px !default;
$ka-cell-padding: 8px 20px !default;
$ka-column-resize-border-color: darken($ka-border-color, 10) !default;
$ka-filter-row-cell-padding: 0 20px 15px 20px;
$ka-font-size: 14px !default;
$ka-group-cell-padding: 8px 10px !default;
$ka-group-row-background-color: #F9FBFC !default;
$ka-group-row-border-color: white !default;
$ka-group-row-border-size: 1px !default;
$ka-icon-font-size: 10px !default;
$ka-input-validation-background-color: #FFE7E7 !default;
$ka-loading-backdrop-color: #ffffff88 !default;
$ka-loading-background-color-base: rgb(116, 125, 134) !default;
$ka-paging-index-active-background-color: #F1F5F7 !default;
$ka-paging-index-active-color: #747D86 !default;
$ka-paging-index-color: $ka-thead-color !default;
$ka-paging-pages-margin: 0;
$ka-paging-pages-padding: 0 10px;
$ka-row-border-size: 2px !default;
$ka-row-hover-background-color: null !default;
$ka-selected-border-color: darken($ka-border-color, 7) !default;
$ka-selecter-background-color: #F7FcFd !default;
$ka-summary-cell-padding: 12px 20px !default;
$ka-thead-background-color: #F1F5F7 !default;
$ka-thead-cell-padding: 15px 20px !default;
$ka-validation-background-color: #FF0C0C !default;
$ka-validation-color: white !default;
$ka-validation-message-font-size: 12px !default;
$ka-validation-message-padding: 0 10px !default;
$ka-header-cell-height: 47px !default;


$ka-background-color: transparent;
$ka-row-border-size: 20px;
$ka-border-color: transparent;

.ka-icon {
    font-weight: 100;
    font-size: 10px;
    font-family: 'icons';
}

.ka-icon-sort {
    margin-left: 3px;
    position: absolute;
}

.ka-pointer {
    cursor: pointer;
}

.table {
    margin-bottom: 0;
}

tr {
    line-height: 40px;
    min-height: 40px;
    height: 40px;
    td {
        border-radius: 10px;
        background-color: #ffffff;
        border: 2px solid #eee;
        border-left-width: 0px;
        border-right-width: 0px;

        :first {
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
            border-right-width: 0px !important;
        }
        :last {
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
            border-left-width: 0px !important;
        }
    }
}

.ka-no-data-row {
    text-align: center;
}

.ka-paging {
    padding: 15px;

    ul {
        margin-bottom: 0;
    }
}

.form-check-input {
    margin-top: 12px;
}

.ka-paging-page-index {
    cursor: pointer;
}

*/

/*
$ka-background-color: #2c2c2c;
$ka-border-color: #4d4d4d;
$ka-cell-hover-background-color: transparentize(#fff, 0.8);
$ka-color-base: #fefefe;
$ka-input-background-color: $ka-background-color;
$ka-input-border-color: $ka-border-color;
$ka-input-color: $ka-color-base;
$ka-row-hover-background-color: transparentize(#fff, 0.9);
$ka-thead-background-color: #1b1b1b;
$ka-thead-color: #c5c5c5;
*/
.ka {
    width: 100%;
}

table.ka-table {
    font-family: "Inter Medium";
    font-weight: 500;
    border-collapse: separate !important;
    border-spacing: 0 0.255rem;
    margin-top: -0.25rem;
    table-layout: auto;
    white-space: nowrap;

    thead {
        border: none;

        tr {
            border: none;
            height: 3rem;

            th {
                font-family: "Inter Medium";
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 500;
                color: var(--chakra-colors-contentT20);
                background-color: var(--chakra-colors-primaryT95);
                padding: 0.75rem !important;
                border-top: 1px solid var(--chakra-colors-primaryT80);
                border-bottom: 1px solid var(--chakra-colors-primaryT80);
                z-index: auto !important;

                &:first-of-type {
                    border-left: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-left-radius: 0.25rem !important;
                    border-bottom-left-radius: 0.25rem !important;
                }

                &:last-of-type {
                    border-right: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-right-radius: 0.25rem !important;
                    border-bottom-right-radius: 0.25rem !important;
                }

                span.ka-icon-sort {
                    display: inline-block;
                }
            }
        }
    }

    tbody {
        border: none;

        tr {
            border: none;
            height: 3rem;

            /*
            cursor: pointer;
            &:hover{
                background-color: #efefef;
            }
            */

            td {
                font-family: "Inter Regular";
                font-size: 0.875rem;
                line-height: 0.875rem;
                font-weight: 500;
                color: var(--chakra-colors-contentT40);
                padding: 0.75rem !important;
                border-top: 1px solid var(--chakra-colors-primaryT80);
                border-bottom: 1px solid var(--chakra-colors-primaryT80);

                &:first-of-type {
                    border-left: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-left-radius: 0.25rem !important;
                    border-bottom-left-radius: 0.25rem !important;
                }

                &:last-of-type {
                    border-right: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-right-radius: 0.25rem !important;
                    border-bottom-right-radius: 0.25rem !important;
                }
            }
        }
    }
}

.ka-paging {
    background-color: var(--chakra-colors-primaryT95);
    border: 1px solid var(--chakra-colors-primaryT80) !important;
    border-top-left-radius: 0.25rem !important;
    padding: 0.75rem !important;
    .ka-paging-pages,
    .ka-paging-sizes {
        padding: unset;
        li {
            background-color: var(--chakra-colors-outlineButtonBg);
            padding: 0.5rem 0.8rem !important;
            border-radius: 0.1875rem;
            min-width: unset !important;
            font-size: 0.75rem;
            text-align: center;
            border: 1px solid var(--chakra-colors-outlineButtonBorder)!important;
            line-height: 1rem;
            color: var(--chakra-colors-outlineButtonText);
            margin: 0 0.1875rem;

            &.ka-paging-size-active,
            &.ka-paging-page-index-active {
                background-color: var(--chakra-colors-primaryButtonBg);
                border: none;
                color: var(--chakra-colors-primaryButtonText);
            }
        }
    }
    .ka-loading-icon{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        animation: ka-loading-animation 1s linear infinite alternate;
        position: relative;
        left: -20px;
    }
}

@keyframes ka-loading-animation{
    0%{
      background-color: var(--chakra-colors-primary);
      box-shadow: 20px 0px 0px 0px var(--chakra-colors-primaryT80),
                  40px 0px 0px 0px var(--chakra-colors-primaryT80);
    }
    25%{
      background-color: var(--chakra-colors-primaryT60);
      box-shadow: 20px 0px 0px 0px var(--chakra-colors-primary),
                  40px 0px 0px 0px var(--chakra-colors-primaryT80);
    }
    75%{
      background-color: var(--chakra-colors-primaryT60);
      box-shadow: 20px 0px 0px 0px var(--chakra-colors-primaryT80),
                  40px 0px 0px 0px var(--chakra-colors-primary);
    }
    100%{
      box-shadow: none;
    }
}