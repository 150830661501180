:root {
    --chakra-colors-chakra-body-bg: #f5f5f5 !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

div.chakra-form-control[data-invalid] {
    div.chakra-input__group.ra {
        input {
            border-right-width: 0 !important;
        }
        div.chakra-input__right-addon {
            border-color: var(--chakra-colors-primaryInputFieldInvalidBorder);
            box-shadow: var(--chakra-colors-boxShadowFormEleInvalid);
            border-left-width: 0 !important;
        }
    }
    div.chakra-input__group.la {
        input {
            border-left-width: 0 !important;
        }
        div.chakra-input__left-addon {
            border-color: var(--chakra-colors-primaryInputFieldInvalidBorder);
            box-shadow: var(--chakra-colors-boxShadowFormEleInvalid);
            border-right-width: 0 !important;
        }
    }
}
div.chakra-form-control[data-focus] {
    div.chakra-input__group.ra {
        input {
            border-right-width: 0 !important;
        }
        div.chakra-input__right-addon {
            border-color: var(--chakra-colors-primaryInputFieldFocusBorder);
            box-shadow: var(--chakra-colors-boxShadowFormEle);
            border-left-width: 0 !important;
        }
    }
    div.chakra-input__group.la {
        input {
            border-left-width: 0 !important;
        }
        div.chakra-input__left-addon {
            border-color: var(--chakra-colors-primaryInputFieldFocusBorder);
            box-shadow: var(--chakra-colors-boxShadowFormEle);
            border-right-width: 0 !important;
        }
    }
}

@import "ka-table/style.scss";
@import "./ka-table.scss";
